//useEffect = Método chamado ao carragar a página;
import React, { useEffect, useState, useContext} from 'react';
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import UserContext from '../../contexts/UserContext';
import Api from '../../services/Api'
import ListItemCashRegister from '../../components/ListItemCashRegister'
import Functions from '../../services/Functions';
import './styles.css';
import '../../App.css';

export default function DashboardCashRegister({setLoading}) {
  const {user} = useContext(UserContext);
  const isAdmin = user[0].user.admin;
  const isAdminShop = user[0].user.adminShop;
  const [cashRegisters, setCashRegisters] = useState([]);
  const [shops, setShops] = useState([]);
  const [selectedShop, setSelectedShop] = useState(user[0].user.adminShop ? user[0].user.shop._id : "");
  const [dateRange, setDateRange] = useState([new Date(new Date().setDate(new Date().getDate() - 30)), new Date()]);

  /*useEffect(() => {
    const today = new Date();
    const [firstDay, lastDay] = Functions.getFirstAndLastDayOfMonth(today);
    setDateRange([firstDay, lastDay]);
  }, []); // O array vazio [] garante que o efeito só seja executado na montagem*/

  const [startDate, endDate] = dateRange;

 // Executa assim q a página é aberta;
  useEffect(() => {
    if(isAdmin){
      loadAllShops();
    }
  }, []);

  useEffect(() => {
    if(endDate && selectedShop){
      loadCashRegister(selectedShop);
    }
  }, [endDate, selectedShop]);

  async function loadCashRegister(shop) {
    try {
      setLoading(true);
      const response = await Api.getItems(`/get-cash-register/${shop}/${startDate.toISOString().substr(0,10)}/${endDate.toISOString().substr(0,10)}`);
      setCashRegisters(response);
      setLoading(false);
    } catch (error) {
      console.log(error)
    }
  }

  async function loadAllShops() {
    try {
      setLoading(true);
      const response = await Api.getShops();
      setShops(response);
      setSelectedShop(response[0]?._id);
      //loadCashRegister(user[0].user.adminShop ? user[0].user.shop._id : response[0]?._id);
      setLoading(false);
    } catch (error) {
      console.log(error)
    }
  }

  async function addCashRegister(){
    const copy = Array.from(cashRegisters);
    // unshift() - Add no inicio do vetor;  push() - add no fim do vetor; 
    copy.unshift({
      _id: copy.length +1,
      date: "", 
      shop: user[0]?.user?.shop, 
      user: user[0]?.user,
      quantityOrders: 0, 
      description: '', 
      operations: [
        {
          operation: "inflow", 
          name: "Vendas Aplicativo", 
          value: 0
        },
        /*{
          operation: "inflow", 
          name: "Vendas Ifood", 
          value: 0
        },
        {
          operation: "inflow", 
          name: "Vendas Aiqfome", 
          value: 0
        },
        {
          operation: "inflow", 
          name: "Vendas Delivery Much", 
          value: 0
        },*/
        {
          operation: "inflow", 
          name: "Vendas Apps Extras", 
          value: 0
        },
        {
          operation: "inflow", 
          name: "Vendas Extras", 
          value: 0
        },
        /*{
          operation: "outflow", 
          name: "Cartão Balcão", 
          value: 0
        },*/
        {
          operation: "outflow", 
          name: "Cartões", 
          value: 0
        },
        {
          operation: "outflow", 
          name: "Pagamento On-line", 
          value: 0
        },
        {
          operation: "outflow", 
          name: "Taxa Delivery", 
          value: 0
        },
        {
          operation: "outflow", 
          name: "Dinheiro", 
          value: 0
        },
        { 
          operation: "outflow", 
          name: "PIX", 
          value: 0
        },
      ],
      active: true
    });
    await setCashRegisters(copy);
    //Gambiarra
    document.getElementsByClassName("headList")[0].click();
  }

  const onPrintCashRegisterClosures = (cashRegisters) => {
    // Agrupar os fechamentos por data
    const closuresByDate = cashRegisters.reduce((acc, register) => {
        const date = register.date?.substr(0, 10); // Mantém a data no formato YYYY-MM-DD
        if (!acc[date]) {
            acc[date] = [];
        }
        acc[date].push(register);
        return acc;
    }, {});

    let html = "";
    html += "<style>table {width: 100%;} th, td {text-align: left; border: 1px solid black; padding: 8px;} @media print {body {width: 70mm; height: 29.7cm;}}</style>";

    // Supondo que todos os registros são da mesma loja, pegue a primeira
    const shopName = cashRegisters[0]?.shop?.name || '';
    html += `<h5>FECHAMENTO DE CAIXA: ${shopName} | PERÍODO: ${startDate.toISOString().substr(0, 10).split("-").reverse().join("/")} - ${endDate.toISOString().substr(0, 10).split("-").reverse().join("/")}</h5>`;
    html += `<h5></h5>`;

    // Obter as datas em ordem crescente
    const sortedDates = Object.keys(closuresByDate).sort((a, b) => new Date(a) - new Date(b));

    // Para cada data, cria um bloco de fechamentos
    for (const date of sortedDates) {
        const registers = closuresByDate[date];
        const totalOrders = registers.reduce((sum, register) => sum + register.quantityOrders, 0);

        html += `<h6>DATA: ${date.split('-').reverse().join('/')} | QTD DE PEDIDOS: ${totalOrders}</h6>`;
        html += "<table>";
        html += "<thead><tr><th>Operação</th><th>Valor</th></tr></thead>";
        html += "<tbody>";

        let totalInflow = 0;
        let totalOutflow = 0;

        registers.forEach(register => {
            register.operations.forEach(op => {
                const value = op.value;
                html += `<tr><td>${op.name}</td><td>R$ ${value.toFixed(2).replace('.', ',')}</td></tr>`;
                if (op.operation === "inflow") {
                    totalInflow += value;
                } else if (op.operation === "outflow") {
                    totalOutflow += value;
                }
            });
        });

        // Apresentar totais apenas ao final de cada listagem de fechamento
        html += `<tfoot><tr><td><strong>Total Vendas</strong></td><td><strong>R$ ${(totalInflow).toFixed(2).replace('.', ',')}</strong></td></tr>`;
        html += `<tr><td><strong>Total Valores</strong></td><td><strong>R$ ${(totalOutflow).toFixed(2).replace('.', ',')}</strong></td></tr>`;
        html += `<tr><td><strong>Total</strong></td><td><strong>R$ ${((totalInflow - totalOutflow) * -1).toFixed(2).replace('.', ',')}</strong></td></tr></tfoot>`;
        
        html += "</tbody>";
        html += "</table>";
        html += "<br>";
    }

    // Criar uma nova janela para impressão
    let fakeIFrame = window.document.createElement("iframe");
    fakeIFrame.setAttribute('style', 'height: 0; width: 0; position: absolute;');
    document.body.appendChild(fakeIFrame);
    let fakeContent = fakeIFrame.contentWindow;
    fakeContent.document.open();
    fakeContent.document.write(html);
    fakeContent.document.close();
    fakeContent.focus();
    fakeIFrame.addEventListener("load", () => {
        fakeContent.print();
    });
  }

  return (
    <div id="leadList" className="bodyDashboard">
      <div className="v-center">
        <div className="col-12 col-md-4">
          {isAdmin &&
            <select className="title" value={selectedShop} style={{border: 0, background: 'transparent', cursor: 'pointer'}} onChange={event => setSelectedShop(event.target.value)}>
              {shops.map((item) => (
                <option key={item._id} value={item._id}>{item.name}</option>
              ))}
            </select>
          }
        </div>
        <div className="col-6 col-md-4 text-center">
          <DatePicker 
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            onChange={(update) => {
              setDateRange(update);
            }}
            className="titleDate"
            dateFormat={'dd/MM/yyyy'}
            withPortal 
          />
        </div>
        <div className="col-6 col-md-4 text-right">
          {isAdminShop &&
            <button type="button" onClick={addCashRegister} className="btnNew" style={{marginRight: 5}}><i className="fa fa-plus fa-lg"></i></button>
          }
          <button type="button" onClick={() => onPrintCashRegisterClosures(cashRegisters)} className="btnNew"><i className="fa fa-print fa-lg"></i></button>
        </div>
      </div>
        <div className="col-12">
          <ul className="dashboardList">
            {cashRegisters.length > 0 ?
            cashRegisters.map(item => (
              <ListItemCashRegister
                key={item._id}
                id={item._id}
                date={item.date}
                shop={item.shop}
                user={item.user}
                quantityOrders={item.quantityOrders}
                description={item.description}
                operations={item.operations}
                cashRegisters={cashRegisters}
                createdAt={item.createdAt}
                updatedAt={item.updatedAt}
                loadCashRegister={(shop) => loadCashRegister(shop)}
                setLoading={setLoading}
              />
            )) :
            <div className="col-12 text-center">
              <span className="labelFilter">Nenhum registro encontrado!</span>
            </div>}
          </ul>
      </div>
    </div>
  )
 
}